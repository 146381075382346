import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'
import ToonEventRegisterSection from './components/ToonEventRegisterSection'

const EVENT_TYPES = [
  { value: 'basic', label: '기본이벤트' },
  { value: 'mission', label: '미션이벤트' },
  { value: 'themeSale', label: '기획전' },
  { value: 'timeSale', label: '타임특가' },
  { value: 'poll', label: '투표이벤트' },
  { value: 'leaderboard', label: '리더보드 이벤트' },
]

const ToonEventAdminScreen = observer(() => {
  const [eventList, setEventList] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [filterType, setFilterType] = useState('all')
  const [currentTab, setCurrentTab] = useState('list')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [searchType, setSearchType] = useState('title')
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState('desc')

  const [formData, setFormData] = useState({
    eventType: '',
    eventTitle: '',
    eventImageUri: '',
    subImageUri: '',
    backgroundColor: '',
    toonListTitle: '',
    toonIdList: [],
    novelIdList: [],
    notice: '',

    // CTA 버튼 정보
    ctaOneInfo: {
      ctaType: '',
      ctaText: '',
      ctaPath: '',
      ctaProp: '',
      ctaColor: '',
      ctaTextColor: '',
      ctaBorderColor: '',
    },

    // 미션 이벤트 필드
    missionInfo: {
      missions: [],
      rewards: [],
      completionConditions: [],
      notice: '',
      goodsName: '',
      goodsNameKor: '',
      iconUri: '',
      goodsNormalGifUri: '',
      goodsPopGifUri: '',
      keyColor: '',
      subColor: '',
      backgroundImageUri: '',
    },

    // 기획전 이벤트 필드
    themeSaleInfo: {
      columnNumber: 1,
      isMultiTab: false,
      tabInfo: {
        tabCount: 1,
        tabTitle: [''],
        tabToonIdList: [[]],
      },
    },
  })

  const fetchData = async () => {
    const result = await altoonCMSBackendApis.loadToonEventPage()
    if (result?.status === 200) {
      setEventList(result?.data)
    }
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    fetchData()
    setTimeout(() => {
      AltoonCMSUserStore.setIsLoading(false)
    }, 500)
  }, []) // currentPage 또는 itemsPerPage가 변경될 때마다 요청

  const RegisteredEventList = () => {
    const [tempSearchQuery, setTempSearchQuery] = useState('')

    const filteredAndSortedEventList = eventList
      .filter((event) => {
        // 검색 필터
        if (searchQuery) {
          if (searchType === 'title') {
            if (
              !event.eventTitle
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase())
            ) {
              return false
            }
          } else if (searchType === 'id') {
            if (!event._id?.toLowerCase().includes(searchQuery.toLowerCase())) {
              return false
            }
          }
        }

        // 이벤트 타입 필터
        if (filterType !== 'all' && event.eventType !== filterType) return false
        return true
      })
      .sort((a, b) => {
        if (sortBy === 'createdAt') {
          return sortOrder === 'desc'
            ? new Date(b.createdAt) - new Date(a.createdAt)
            : new Date(a.createdAt) - new Date(b.createdAt)
        }
        return sortOrder === 'desc'
          ? b[sortBy]?.localeCompare(a[sortBy])
          : a[sortBy]?.localeCompare(b[sortBy])
      })

    // 페이지네이션 계산
    const totalPages = Math.ceil(
      filteredAndSortedEventList.length / itemsPerPage,
    )
    const paginatedEventList = filteredAndSortedEventList.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    )

    return (
      <div className='w-full'>
        {/* 검색 영역 */}
        <div className='mb-6 p-6 bg-white rounded-xl shadow-sm border border-gray-100'>
          <div className='flex gap-6 items-center mb-6'>
            <div className='flex-1'>
              <div className='flex gap-3 items-center'>
                <select
                  value={searchType}
                  onChange={(e) => {
                    setSearchType(e.target.value)
                    setSearchQuery('')
                  }}
                  className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                >
                  <option value='title'>제목</option>
                  <option value='id'>ID</option>
                </select>
                <div className='flex-1 flex gap-2'>
                  <input
                    type='text'
                    value={tempSearchQuery}
                    onChange={(e) => setTempSearchQuery(e.target.value)}
                    placeholder={
                      searchType === 'title'
                        ? '제목으로 검색...'
                        : 'ID로 검색...'
                    }
                    className='flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setSearchQuery(tempSearchQuery)
                      setCurrentPage(1)
                    }}
                    className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  >
                    검색
                  </button>
                </div>
              </div>
            </div>
            <div className='flex gap-3 items-center'>
              <span className='text-gray-600 font-medium'>정렬:</span>
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className='pl-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='createdAt'>생성일</option>
                <option value='eventTitle'>제목</option>
                <option value='eventType'>이벤트 타입</option>
              </select>
              <button
                type='button'
                onClick={() =>
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                }
                className='px-3 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors'
              >
                {sortOrder === 'asc' ? '↑' : '↓'}
              </button>
            </div>
          </div>

          {/* 필터 영역 */}
          <div className='flex gap-6 flex-wrap items-center'>
            <div className='flex items-center'>
              <select
                id='typeFilter'
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='all'>전체</option>
                {EVENT_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='flex items-center'>
              <select
                id='itemsPerPage'
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value={10}>10개씩 보기</option>
                <option value={20}>20개씩 보기</option>
                <option value={50}>50개씩 보기</option>
              </select>
            </div>
          </div>
        </div>

        {/* 결과 카운트 표시 */}
        <div className='mb-4 text-sm text-gray-600 font-medium'>
          총 {filteredAndSortedEventList.length}개의 이벤트 ({currentPage} /{' '}
          {totalPages} 페이지)
        </div>

        {/* 테이블 */}
        <div className='overflow-hidden rounded-xl border border-gray-200 bg-white shadow-sm'>
          <div className='overflow-x-auto'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50 text-center text-xs uppercase font-medium text-gray-500 tracking-wider'>
                <tr>
                  <th className='px-6 py-3'>ID</th>
                  <th className='px-6 py-3'>이벤트 타입</th>
                  <th className='px-6 py-3'>제목</th>
                  <th className='px-6 py-3'>이미지/배경색</th>
                  {/* <th className='px-6 py-3'>배경색</th> */}
                  <th className='px-6 py-3'>생성일</th>
                  <th className='px-6 py-3'>관리</th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {paginatedEventList.map((item) => (
                  <tr
                    key={item._id}
                    className='hover:bg-gray-50 cursor-pointer transition-colors'
                    onClick={() => setSelectedEvent(item)}
                  >
                    <td className='pl-4 py-4 text-[6px] text-gray-900'>
                      {item._id}
                    </td>
                    <td className='px-6 py-4 text-center text-sm text-gray-900'>
                      {EVENT_TYPES.find((type) => type.value === item.eventType)
                        ?.label || item.eventType}
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-900'>
                      {item.eventTitle}
                    </td>
                    <td className='px-6 py-4 flex justify-center'>
                      {item.eventImageUri && (
                        <img
                          src={item.eventImageUri}
                          alt={item.eventTitle}
                          className='w-24 h-14 object-cover rounded-lg shadow-sm mr-2'
                        />
                      )}
                      <div
                        className='w-8 h-8 rounded-full'
                        style={{ backgroundColor: `#${item.backgroundColor}` }}
                      />
                    </td>

                    <td className='px-6 py-4 text-sm text-gray-900 text-center'>
                      {moment(item.createdAt).format('YY.MM.DD')}
                      <br />
                      {moment(item.createdAt).format('HH:mm:ss')}
                    </td>
                    <td className='px-6 py-4 text-sm text-center font-medium space-x-2'>
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                        onClick={(e) => {
                          e.stopPropagation()
                          const deleteConfirmation =
                            window.confirm('삭제하시겠습니까?')
                          if (deleteConfirmation) {
                            altoonCMSBackendApis
                              .deleteToonPromotion({
                                _id: item._id,
                                promotionType: 'eventPage',
                              })
                              .then((result) => {
                                if (result?.status === 200) {
                                  alert('삭제되었습니다.')
                                  fetchData()
                                } else {
                                  alert('오류가 발생하였습니다.')
                                }
                              })
                          }
                        }}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지네이션 컨트롤 */}
        <div className='mt-6 flex justify-center gap-2 items-center'>
          <button
            type='button'
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'<<'}
          </button>
          <button
            type='button'
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'<'}
          </button>
          <span className='px-4 py-2 text-sm text-gray-700 font-medium'>
            {currentPage} / {totalPages}
          </span>
          <button
            type='button'
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'>'}
          </button>
          <button
            type='button'
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'>>'}
          </button>
        </div>
      </div>
    )
  }

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleNestedFieldChange = (parent, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [field]: value,
      },
    }))
  }

  const handleArrayFieldChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value.split('\n').filter((item) => item.trim()),
    }))
  }

  const handleEdit = () => {
    setIsEditMode(true)
    setFormData(selectedEvent)
  }

  const handleSave = async () => {
    try {
      // 기존 데이터와 새로운 데이터를 병합
      const updatedData = {
        ...selectedEvent,
        ...formData,
        missionInfo: {
          ...selectedEvent.missionInfo,
          ...formData.missionInfo,
        },
        themeSaleInfo: {
          ...selectedEvent.themeSaleInfo,
          ...formData.themeSaleInfo,
        },
      }

      const result = await altoonCMSBackendApis.updateToonEvent({
        _id: selectedEvent._id,
        ...updatedData,
      })
      if (result?.status === 200) {
        alert('수정되었습니다.')
        setIsEditMode(false)
        fetchData()
      } else {
        alert('오류가 발생하였습니다.')
      }
    } catch (error) {
      console.error('Error updating event:', error)
      alert('오류가 발생하였습니다.')
    }
  }

  const handleCancel = () => {
    setIsEditMode(false)
    setFormData(selectedEvent)
  }

  const renderModalContent = () => {
    if (!selectedEvent) return null

    return (
      <div className='grid grid-cols-2 gap-6'>
        <div>
          {selectedEvent.eventImageUri && (
            <img
              src={selectedEvent.eventImageUri}
              alt={selectedEvent.eventTitle}
              className='w-full h-auto rounded-lg shadow-lg object-contain'
            />
          )}
          {selectedEvent.eventType === 'mission' && (
            <div className='space-y-4'>
              <div className='flex items-center gap-4'>
                <span className='text-sm font-medium text-gray-500'>
                  상단 이미지
                </span>
                <img
                  src={selectedEvent.backgroundImageUri}
                  alt={selectedEvent.eventTitle}
                  className='w-[20vw] h-auto rounded-lg shadow-lg'
                />
              </div>
              <div className='flex items-center gap-4'>
                <span className='text-sm font-medium text-gray-500'>
                  아이콘
                </span>
                <img
                  src={selectedEvent.iconUri}
                  alt={selectedEvent.eventTitle}
                  className='w-[10vw] h-auto rounded-lg shadow-lg'
                />
              </div>
              <div className='flex items-center gap-4'>
                <span className='text-sm font-medium text-gray-500'>
                  일반상태
                </span>
                <img
                  src={selectedEvent.goodsNormalGifUri}
                  alt={selectedEvent.eventTitle}
                  className='w-[10vw] h-auto rounded-lg shadow-lg'
                />
              </div>
              <div className='flex items-center gap-4'>
                <span className='text-sm font-medium text-gray-500'>
                  터지는 상태
                </span>
                <img
                  src={selectedEvent.goodsPopGifUri}
                  alt={selectedEvent.eventTitle}
                  className='w-[10vw] h-auto rounded-lg shadow-lg'
                />
              </div>
            </div>
          )}
        </div>
        <div className='space-y-4'>
          <div className='grid grid-cols-3 gap-4'>
            {isEditMode ? (
              <>
                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  이벤트 타입
                </div>
                <div className='col-span-2'>
                  <select
                    value={formData.eventType}
                    onChange={(e) =>
                      handleFieldChange('eventType', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                  >
                    {EVENT_TYPES.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  제목
                </div>
                <div className='col-span-2'>
                  <input
                    type='text'
                    value={formData.eventTitle || ''}
                    onChange={(e) =>
                      handleFieldChange('eventTitle', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  설명
                </div>
                <div className='col-span-2'>
                  <input
                    type='text'
                    value={selectedEvent.description || ''}
                    onChange={(e) =>
                      handleNestedFieldChange(
                        'event',
                        'description',
                        e.target.value,
                      )
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  이벤트 이미지 URL
                </div>
                <div className='col-span-2'>
                  <input
                    type='text'
                    value={formData.eventImageUri || ''}
                    onChange={(e) =>
                      handleFieldChange('eventImageUri', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  배경색
                </div>
                <div className='col-span-2'>
                  <input
                    type='text'
                    value={formData.backgroundColor || ''}
                    onChange={(e) =>
                      handleFieldChange('backgroundColor', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  작품목록 제목
                </div>
                <div className='col-span-2'>
                  <input
                    type='text'
                    value={formData.toonListTitle || ''}
                    onChange={(e) =>
                      handleFieldChange('toonListTitle', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  작품 ID 목록
                </div>
                <div className='col-span-2'>
                  <textarea
                    value={formData.toonIdList?.join('\n') || ''}
                    onChange={(e) =>
                      handleArrayFieldChange('toonIdList', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                    rows={3}
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  웹소설 ID 목록
                </div>
                <div className='col-span-2'>
                  <textarea
                    value={formData.novelIdList?.join('\n') || ''}
                    onChange={(e) =>
                      handleArrayFieldChange('novelIdList', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                    rows={3}
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  유의사항
                </div>
                <div className='col-span-2'>
                  <textarea
                    value={selectedEvent.notice || ''}
                    onChange={(e) =>
                      handleNestedFieldChange('event', 'notice', e.target.value)
                    }
                    className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                    rows={10}
                  />
                </div>

                {selectedEvent.eventType === 'themeSale' && (
                  <>
                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      주요 색상
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.themeSaleInfo?.keyColor || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'themeSaleInfo',
                            'keyColor',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      컬럼 수
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='number'
                        value={formData.themeSaleInfo?.columnNumber || 1}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'themeSaleInfo',
                            'columnNumber',
                            Number(e.target.value),
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                        min={1}
                        max={4}
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      멀티 탭
                    </div>
                    <div className='col-span-2'>
                      <select
                        value={
                          formData.themeSaleInfo?.isMultiTab ? 'true' : 'false'
                        }
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'themeSaleInfo',
                            'isMultiTab',
                            e.target.value === 'true',
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      >
                        <option value='true'>사용</option>
                        <option value='false'>미사용</option>
                      </select>
                    </div>

                    {formData.themeSaleInfo?.isMultiTab && (
                      <>
                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          탭 관리
                        </div>
                        <div className='col-span-2'>
                          <button
                            type='button'
                            onClick={() => {
                              const newTabCount =
                                (formData.themeSaleInfo?.tabInfo?.tabCount ||
                                  0) + 1
                              if (newTabCount <= 5) {
                                handleNestedFieldChange(
                                  'themeSaleInfo',
                                  'tabInfo',
                                  {
                                    ...formData.themeSaleInfo?.tabInfo,
                                    tabCount: newTabCount,
                                    tabTitle: [
                                      ...(formData.themeSaleInfo?.tabInfo
                                        ?.tabTitle || []),
                                      '',
                                    ],
                                    tabToonIdList: [
                                      ...(formData.themeSaleInfo?.tabInfo
                                        ?.tabToonIdList || []),
                                      [],
                                    ],
                                  },
                                )
                              }
                            }}
                            className='px-3 py-1 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700'
                          >
                            탭 추가
                          </button>
                        </div>

                        {Array.from({
                          length:
                            formData.themeSaleInfo?.tabInfo?.tabCount || 0,
                        }).map((_, index) => (
                          <div
                            key={index}
                            className='col-span-3 p-4 border border-gray-200 rounded-lg space-y-4'
                          >
                            <div className='flex justify-between items-center'>
                              <h4 className='text-md font-medium text-gray-900'>
                                탭 {index + 1}
                              </h4>
                              <button
                                type='button'
                                onClick={() => {
                                  const newTabTitles = [
                                    ...(formData.themeSaleInfo?.tabInfo
                                      ?.tabTitle || []),
                                  ]
                                  const newTabToonIdLists = [
                                    ...(formData.themeSaleInfo?.tabInfo
                                      ?.tabToonIdList || []),
                                  ]
                                  newTabTitles.splice(index, 1)
                                  newTabToonIdLists.splice(index, 1)

                                  handleNestedFieldChange(
                                    'themeSaleInfo',
                                    'tabInfo',
                                    {
                                      ...formData.themeSaleInfo?.tabInfo,
                                      tabCount:
                                        (formData.themeSaleInfo?.tabInfo
                                          ?.tabCount || 1) - 1,
                                      tabTitle: newTabTitles,
                                      tabToonIdList: newTabToonIdLists,
                                    },
                                  )
                                }}
                                className='px-2 py-1 text-sm bg-red-600 text-white rounded-md hover:bg-red-700'
                              >
                                탭 삭제
                              </button>
                            </div>

                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              탭 제목
                            </div>
                            <div className='col-span-2'>
                              <input
                                type='text'
                                value={
                                  formData.themeSaleInfo?.tabInfo?.tabTitle?.[
                                    index
                                  ] || ''
                                }
                                onChange={(e) => {
                                  const newTabTitles = [
                                    ...(formData.themeSaleInfo?.tabInfo
                                      ?.tabTitle || []),
                                  ]
                                  newTabTitles[index] = e.target.value
                                  handleNestedFieldChange(
                                    'themeSaleInfo',
                                    'tabInfo',
                                    {
                                      ...formData.themeSaleInfo?.tabInfo,
                                      tabTitle: newTabTitles,
                                    },
                                  )
                                }}
                                className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                              />
                            </div>

                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              작품 ID 목록
                            </div>
                            <div className='col-span-2'>
                              <textarea
                                value={
                                  formData.themeSaleInfo?.tabInfo?.tabToonIdList?.[
                                    index
                                  ]?.join('\n') || ''
                                }
                                onChange={(e) => {
                                  const newTabToonIdLists = [
                                    ...(formData.themeSaleInfo?.tabInfo
                                      ?.tabToonIdList || []),
                                  ]
                                  newTabToonIdLists[index] = e.target.value
                                    .split('\n')
                                    .filter((id) => id.trim())
                                  handleNestedFieldChange(
                                    'themeSaleInfo',
                                    'tabInfo',
                                    {
                                      ...formData.themeSaleInfo?.tabInfo,
                                      tabToonIdList: newTabToonIdLists,
                                    },
                                  )
                                }}
                                className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                                rows={5}
                                placeholder='작품 ID를 줄바꿈으로 구분하여 입력하세요'
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}

                {selectedEvent.eventType === 'mission' && (
                  <>
                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      재화명 (e.g. heart)
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.goodsName || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'goodsName',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      재화명 (한글) (e.g. 하트)
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.goodsNameKor || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'goodsNameKor',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      재화이미지(아이콘)
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.iconUri || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'iconUri',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      일반상태재화 GIF
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.goodsNormalGifUri || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'goodsNormalGifUri',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      터지는재화 GIF
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.goodsPopGifUri || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'goodsPopGifUri',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      주요 색상 (e.g. FE6564)
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.keyColor || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'keyColor',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      보조 색상 (e.g. F9C7C7)
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.subColor || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'subColor',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      배경 이미지 URL
                    </div>
                    <div className='col-span-2'>
                      <input
                        type='text'
                        value={formData.missionInfo?.backgroundImageUri || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'backgroundImageUri',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                      />
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      미션 유의사항
                    </div>
                    <div className='col-span-2'>
                      <textarea
                        value={formData.missionInfo?.notice || ''}
                        onChange={(e) =>
                          handleNestedFieldChange(
                            'missionInfo',
                            'notice',
                            e.target.value,
                          )
                        }
                        className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                        rows={3}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  ID
                </div>
                <div className='col-span-2 text-sm text-gray-900 font-mono'>
                  {selectedEvent._id}
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  이벤트 타입
                </div>
                <div className='col-span-2 text-sm text-gray-900'>
                  {EVENT_TYPES.find(
                    (type) => type.value === selectedEvent.eventType,
                  )?.label || selectedEvent.eventType}
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  제목
                </div>
                <div className='col-span-2 text-sm text-gray-900'>
                  {selectedEvent.eventTitle}
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  설명
                </div>
                <div className='col-span-2 text-sm text-gray-900'>
                  {selectedEvent.description}
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  배경색
                </div>
                <div className='col-span-2'>
                  <div
                    className='w-8 h-8 rounded-full'
                    style={{
                      backgroundColor: `#${selectedEvent.backgroundColor}`,
                    }}
                  />
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  작품목록 제목
                </div>
                <div className='col-span-2 text-sm text-gray-900'>
                  {selectedEvent.toonListTitle}
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  작품 ID 목록
                </div>
                <div className='col-span-2 text-sm text-gray-900'>
                  {selectedEvent.toonIdList?.join(', ')}
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  웹소설 ID 목록
                </div>
                <div className='col-span-2 text-sm text-gray-900'>
                  {selectedEvent.novelIdList?.join(', ')}
                </div>

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  유의사항
                </div>
                <div className='col-span-2 text-sm text-gray-900 whitespace-pre-line'>
                  {selectedEvent.notice}
                </div>

                {selectedEvent.eventType === 'themeSale' && (
                  <>
                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      컬럼 수
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent.columnNumber}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      멀티 탭
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent.isMultiTab ? '사용' : '미사용'}
                    </div>

                    {selectedEvent.isMultiTab && (
                      <>
                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          탭 수
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {selectedEvent.tabInfo?.tabCount}
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          탭 제목
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {selectedEvent.tabInfo?.tabTitle?.join(', ')}
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          탭별 작품 ID 목록
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {selectedEvent.tabInfo?.tabToonIdList
                            ?.map(
                              (list, index) =>
                                `${index + 1}번 탭: ${list.join(', ')}`,
                            )
                            .join('\n')}
                        </div>
                      </>
                    )}
                  </>
                )}

                {selectedEvent.eventType === 'mission' && (
                  <>
                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      재화명 (e.g. heart)
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.goodsName}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      재화명 (한글) (e.g. 하트)
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.goodsNameKor}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      재화이미지(아이콘)
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.iconUri}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      일반상태재화 GIF
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.goodsNormalGifUri}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      터지는재화 GIF
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.goodsPopGifUri}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      주요 색상 (e.g. FE6564)
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.keyColor}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      보조 색상 (e.g. F9C7C7)
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.subColor}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      배경 이미지 URL
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.backgroundImageUri}
                    </div>

                    <div className='col-span-1 text-sm font-medium text-gray-500'>
                      미션 유의사항
                    </div>
                    <div className='col-span-2 text-sm text-gray-900'>
                      {selectedEvent?.notice}
                    </div>
                  </>
                )}

                <div className='col-span-1 text-sm font-medium text-gray-500'>
                  생성일
                </div>
                <div className='col-span-2 text-sm text-gray-900'>
                  {new Date(selectedEvent.createdAt).toLocaleString()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full min-h-screen py-8'>
      {/* 탭 네비게이션 */}
      <div className='border-b border-gray-200'>
        <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
          <button
            type='button'
            onClick={() => {
              setCurrentTab('list')
              setSelectedEvent(null)
              setIsEditMode(false)
            }}
            className={`${
              currentTab === 'list'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2`}
          >
            이벤트페이지 조회
          </button>
          <button
            type='button'
            onClick={() => {
              setCurrentTab('register')
              setSelectedEvent(null)
              setIsEditMode(false)
            }}
            className={`${
              currentTab === 'register'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2`}
          >
            이벤트페이지 등록
          </button>
        </nav>
      </div>
      <div className='w-full my-10 min-w-7xl max-w-10xl select-text mx-auto'>
        <div className='p-4 bg-white rounded-xl'>
          <div className='space-y-6'>
            <div className='flex justify-between items-center'>
              <h1 className='text-2xl font-bold text-gray-900'>
                이벤트 페이지 관리
              </h1>
            </div>

            {currentTab === 'list' ? (
              <div className='space-y-6'>
                {eventList?.length > 0 && <RegisteredEventList />}
                {eventList?.length === 0 && (
                  <div className='text-center py-12'>
                    <p className='text-gray-500'>등록된 이벤트가 없습니다.</p>
                  </div>
                )}
              </div>
            ) : (
              <ToonEventRegisterSection />
            )}
          </div>
        </div>

        {/* 모달 */}
        {selectedEvent && (
          <div
            className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'
            onClick={() => setSelectedEvent(null)}
          >
            <div
              className='bg-white rounded-xl w-[1200px] overflow-y-auto shadow-xl ml-[10vw]'
              style={{ maxHeight: 'calc(100vh - 100px)' }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className='px-6 py-4 border-b border-gray-200 flex justify-between items-center'>
                <h3 className='text-lg font-medium text-gray-900'>
                  이벤트 상세 정보
                </h3>
                <div className='flex gap-2'>
                  {!isEditMode ? (
                    <button
                      type='button'
                      className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                      onClick={handleEdit}
                    >
                      수정
                    </button>
                  ) : (
                    <>
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                        onClick={handleSave}
                      >
                        저장
                      </button>
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                        onClick={handleCancel}
                      >
                        취소
                      </button>
                    </>
                  )}
                  <button
                    type='button'
                    className='text-gray-400 hover:text-gray-500'
                    onClick={() => {
                      setSelectedEvent(null)
                      setIsEditMode(false)
                    }}
                  >
                    <span className='sr-only'>닫기</span>
                    <svg
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className='p-6'>{renderModalContent()}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export default ToonEventAdminScreen
